import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subscription } from '../model/subscription';
import { TrainSegment } from '../model/train-segment';
import { Seat } from '../../shared/components/train/seat/seat-model';
import { DiscountDto } from '../model/discount-dto';
import { DiscountCode } from '../model/discount-code';
import { AuthUser } from '../model/auth-user';

@Injectable({
  providedIn: 'root',
})

export class UtilsService {

  // we create this because dateToIsoString is returning the UTC date time
  // which is not what we want, we want the local date time
  dateToYmd(date: Date) {
    return date.getFullYear() + '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
      date.getDate().toString().padStart(2, '0');
  }

  statusAsText(status: number) {
    switch (Number(status)) {
      case environment.status.expired:
        return 'Expirirat';
      case environment.status.open:
        return 'Nefinalizat';
      case environment.status.canceled:
        return 'Anulat';
      case environment.status.confirmed:
        return 'Confirmat';
      case environment.status.done:
        return 'Finalizat';
      default:
        return 'Nedefinit';
    }
  }

  isExpired(subscription: Subscription) {
    return new Date(subscription.valid_to) < new Date();
  }

  subscriptionTitle(subscription: Subscription) {
    return `Abonament Nr. ${subscription.id} ${subscription.valid_for == 30 ? '(lunar)' : `(${subscription.valid_for} zile)`}`;
  }

  canShowSubscriptionPaymentButton(subscription: Subscription) {
    return subscription.status == environment.status.open &&
      subscription.payment_status == null &&
      subscription.price > 0 &&
      !this.isExpired(subscription);
  }

  canShowSubscriptionBuySeatButton(subscription: Subscription) {
    return subscription.status == environment.status.done &&
      !this.isExpired(subscription);
  }

  stringToTime(time: string | null) {
    if (time == null) {
      return new Date(0, 0, 0, 0, 0, 0);
    }
    const parts = time.split(':');
    return new Date(0, 0, 0, Number(parts[0]), Number(parts[1]), 0);
  }

  computeTripMask(originPos: number, destinationPos: number) {
    if (originPos > destinationPos) {
      return 0;
    }

    var mask = 0;
    for (var i = originPos; i < destinationPos; i++) {
      mask |= Math.pow(2, i - 1);
    }

    return mask;
  }

  computeVat(user: AuthUser | null , price: number) {
    const vatRate = Number(user?.session?.vatRate || 0);
    return (vatRate * price) / (100 + vatRate);
  }
}
